<template>
  <v-dialog v-model="isVisible" max-width="798px" @click:outside="onClose" style="z-index: 9999;">
    <div
      class="white py-5 rounded wallet-pay"
      style="min-height: 550px; overflow-y: auto"
    >
      <!-- <form
        class=""
        :action="`${apiUrl}/custom-offline-wallet-recharge`"
        method="post"
        enctype="multipart/form-data"
      > -->
      <div class="modal-body gry-bg px-3 pt-3 mx-auto">
        <div class="align-items-center gutters-5 row">
          <div class="title-bg">{{ $t('offline_recharge_wallet') }}</div>
          <!-- todo: 国际化 -->
          <div style="font-weight: 400;">{{ $t('recharge_method') }}</div>
          <!-- 充值方式tabs -->
          <div style="background: #FFF4F1; margin-bottom: 20px">
            <div v-for="item in paymentMethods" :key="item.code">
              <label class="aiz-megabox d-block mb-3">
                <!-- todo: 国际化 -->
                <input
                  :value="item.name"
                  type="radio"
                  :name="$t('payment_option')"
                  @change="onTabs(item)"
                  data-id="2"
                  :data-type="item.type"
                  checked
                />
                <span class="d-block p-3 aiz-megabox-elem">
                  <img
                    :src="addResourceImage(item.img)"
                    class="img-fluid mb-2"
                  />
                  <span
                    class="d-block text-right recharge-container"
                    style="width: 70%"
                  >
                    <span
                      class="d-block fw-600 fs-13 recharge-name fc-vi"
                      style="word-break: break-all; white-space: break-spaces"
                    >
                      {{ item.app_title }}
                    </span>
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>

        <div id="manual_payment_data" style="position: relative">
          <!-- bank info -->
          <div
            v-if="type === 'bank_payment' && swiperSwitch"
            class="swiper swiper-container mb-5"
            style="padding: 0 30px"
          >
            <swiper
              :options="carouselOption"
              class="swiper"
              ref="myBankSwiper"
              @slideChange="changeBank"
            >
              <swiper-slide
                v-for="(item, i) in currentBankInfo"
                :key="i"
                class="swiper-slide"
                ref="swiperSlide"
                style="padding: 20px 0;"
              >
                <template v-for="(value, key) in item">
                  <div v-if="value" :key="key" class="swiper__item" style="padding: 0 20px;">
                    <label class="text-primary">
                      {{ $t(key) }}
                    </label>

                    <label
                      style="
                          margin-left: auto;
                          margin-right: 10px;
                          font-size: 13px;
                          font-weight: 600;
                      "
                      class="text-primary"
                    >
                      {{ value }}
                    </label>

                    <div
                      @click="copy"
                      :data-clipboard-text="value"
                      style="cursor: pointer"
                      id="copy"
                    >
                      <img
                        :src="require('@/assets/img/copy-black.png')"
                        style="width: 16px; height: 16px"
                      />
                    </div>
                  </div>
                </template>
              </swiper-slide>
            </swiper>

            <div class="swiper-button swiper-button-prev"></div>
            <div class="swiper-button swiper-button-next"></div>
          </div>

          <!-- payment_instruction -->
          <div
            v-if="type === 'bank_payment' && walletAddressWithFormat"
            id="payment-instruction"
          >
            <div
              class="payment-instruction__title"
              @click="showPaymentInstruction = !showPaymentInstruction"
            >
              <label class="text-primary">{{ $t('payment_instruction') }}</label>
              <img
                :src="
                  showPaymentInstruction
                    ? require('@/assets/img/arrow-top-black.png')
                    : require('@/assets/img/arrow-down-black.png')
                "
                style="width: 10px; height: 6px; margin-left: 9px"
              />
            </div>

            <div
              v-show="showPaymentInstruction"
              class="payment-instruction__container"
            >
              <div
                style="color: #000; font-size: 13px; font-weight: 600"
                v-html="walletAddress"
              ></div>

              <div class="payment-instruction__copy">
                <v-btn
                  id="copy"
                  color="#000"
                  :data-clipboard-text="walletAddressWithFormat"
                  class="payment-instruction__copy-button"
                  @click="copy"
                  style="background-color: #FFA895"
                >
                  <img
                    :src="require('@/assets/img/copy-white.png')"
                    style="width: 16px; height: 16px; margin-right: 4px"
                  />
                  <span style="color: #fff;">{{ $t('copy') }}</span>
                </v-btn>
              </div>
            </div>
          </div>

          <!-- 自定义充值显示、线上充值不显示 -->
          <label
            v-show="['custom_payment'].includes(type)"
            style="font-weight: bold"
            id="pay-ad1"
            class="primary--text"
          >
            <!-- todo: 国际化 -->
            {{ $t('wallet_address') }}
          </label>

          <div
            v-show="['custom_payment'].includes(type)"
            class="card mb-3 p-3"
            style="display: flex; align-items: center; background-color: #FFF4F1; height: 42px;"
            id="pay-ad2"
          >
            <div id="manual_payment_description">
              {{ walletAddressWithFormat }}
            </div>
          </div>

          <div class="card mb-3 p-3">
            <!-- new amount -->
            <template v-if="type === 'bank_payment'">
              <!-- label -->
              <div>
                <label class="primary--text">
                  {{ $t('amount') + ' U.S.Dollar' }}
                  <span class="text-danger">*</span>
                </label>
              </div>

              <div class="new-input mb-3">
                <div>
                  <input
                    v-model="params.amount"
                    type="number"
                    lang="en"
                    class="form-control"
                    min="0"
                    step="0.01"
                    name="amount"
                    :placeholder="$t('amount')"
                    required
                    style="width: 100%; background: #FFF4F1"
                    :style="{
                      border: amountErrorSwitch ? '1px solid red' : 'none'
                    }"
                  />

                  <div v-if="amountErrorSwitch">
                    <span
                      style="color: red; font-size: 12px"
                      v-if="minRecharge && !maxRecharge"
                    >
                      {{
                        $t('min_recharge_amount') + ': $' + minRechargeAmount
                      }}
                    </span>

                    <span
                      style="color: red; font-size: 12px"
                      v-if="!minRecharge && maxRecharge"
                    >
                      {{
                        $t('seller_max_amount_text') + ': $' + maxRechargeAmount
                      }}
                    </span>

                    <span
                      style="color: red; font-size: 12px"
                      v-if="minRecharge && maxRecharge"
                    >
                      {{
                        `${$t(
                          'seller_recharge_min_max'
                        )}: $${minRechargeAmount}-$${maxRechargeAmount}`
                      }}
                    </span>
                  </div>
                </div>

                <div style="font-size: 0">
                  <img
                    :src="require('@/assets/img/currency_exchange.png')"
                    style="width: 18px; height: 18px"
                  />
                </div>

                <v-menu
                  offset-y
                  :close-on-click="menuCloseOnClick"
                  :elevation="2"
                  z-index="99999"
                >
                  <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" style="display: inline-block">
                      <span
                        class="d-flex align-center"
                        style="padding: 10px; border: 1px solid #FFA895"
                      >
                        <span class="opacity-60 mr-3 text-primary">{{ currencyName }}</span>
                        <i class="las la-angle-down ms-1 fs-12 text-primary" />
                      </span>
                    </div>
                  </template>

                  <v-list class="fs-13">
                    <v-list-item
                      v-for="(currency, i) in currencyData"
                      :key="i"
                      class="c-pointer d-flex align-center"
                      @click="switchCurrency(currency)"
                    >
                      <v-list-item-title class="fs-13 opacity-60">
                        {{ currency.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="recharge-text">
                <!-- 最小充值金额 -->
                <div class="min-recharge" v-if="minRecharge && !maxRecharge">
                  {{ $t('seller_min_amount_text') }} {{ minRechargeAmount }}
                </div>
                <!-- 最大充值金额 -->
                <div class="min-recharge" v-if="maxRecharge && !minRecharge">
                  {{ $t('seller_max_amount_text') }} {{ maxRechargeAmount }}
                </div>

                <!-- 最小充值和最大充值都开启 -->
                <div class="min-recharge" v-if="maxRecharge && minRecharge">
                  {{ $t('seller_recharge_min_max') }} {{ minRechargeAmount }}-{{
                    maxRechargeAmount
                  }}
                </div>
              </div>

              <div>
                <div style="text-align: center; margin-bottom: 10px" class="text-primary">
                  {{ $t('amount_to_be_paid') }}
                  <!-- <img :src="require('@/assets/img/equal.png')" style="width: 18px;height: 18px;" /> -->
                </div>

                <div
                  class="d-flex align-center justify-center primary--text"
                  style="color: #e62e04; font-weight: 600; font-size: 23px"
                >
                  <span style="font-size: 16px">{{ symbol }}</span>
                  {{ currencyAmount }}
                </div>
              </div>
            </template>

            <div v-else class="mb-3">
              <div>
                <label class="primary--text">
                  {{ $t('amount') }}
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div
                :style="{
                  border: amountErrorSwitch ? '1px solid red' : 'none'
                }"
              >
                <input
                  v-model="params.amount"
                  type="number"
                  lang="en"
                  class="form-control"
                  min="0"
                  step="0.01"
                  name="amount"
                  :placeholder="$t('amount')"
                  required
                  style="width: 100%; background: #FFF4F1"
                />
              </div>

              <div class="recharge-text">
                <!-- 最小充值金额 -->
                <div class="min-recharge" v-if="minRecharge && !maxRecharge">
                  {{ $t('seller_min_amount_text') }} {{ minRechargeAmount }}
                </div>
                <!-- 最大充值金额 -->
                <div class="min-recharge" v-if="maxRecharge && !minRecharge">
                  {{ $t('seller_max_amount_text') }} {{ maxRechargeAmount }}
                </div>

                <!-- 最小充值和最大充值都开启 -->
                <div class="min-recharge" v-if="maxRecharge && minRecharge">
                  {{ $t('seller_recharge_min_max') }} {{ minRechargeAmount }}-{{
                    maxRechargeAmount
                  }}
                </div>
              </div>
            </div>

            <div v-if="type === 'bank_payment'" id="pay-name">
              <div>
                <div>
                  <label class="primary--text">
                    {{ $t('payment_name') }}
                    <span class="text-danger">*</span>
                  </label>
                </div>
                <div>
                  <input
                    v-model="params.trx_id"
                    type="text"
                    class="form-control mb-3"
                    name="trx_id"
                    :placeholder="$t('payment_name')"
                    required
                    style="width: 100%; background: #FFF4F1"
                  />
                </div>
              </div>
            </div>

            <!-- 线上充值不显示 -->
            <div
              v-show="type !== 'online_payment'"
              class="form-group"
              id="pay-img"
            >
              <label class="col-form-label primary--text">
                {{ $t('photo') }}
                <span class="text-danger">*</span>
              </label>

              <!-- <input :value="params.filepath" name="photo" style="display: none" /> -->

              <v-file-input
                accept="image/*"
                :placeholder="$t('add_receipt')"
                flat
                outlined
                prepend-icon=""
                clearable
                show-size
                v-model="file"
                @change="onUpLoad()"
              ></v-file-input>

              <!-- <div>
                <div class="input-group" data-toggle="aizuploader" data-type="image" required>
                  <div class="input-group-prepend">
                    <div class="input-group-text bg-soft-secondary font-weight-medium">Browse</div>
                  </div>
                  <div class="form-control file-amount">Choose image</div>
                  <span id="payimg"></span>
                </div>
                <div class="file-preview box sm"></div>
              </div> -->
            </div>
          </div>

          <div class="form-group text-right d-flex justify-center" style="padding-bottom: 20px">
            <button
              @click="handleSubmit"
              class="btn btn-sm btn-primary-vi transition-3d-hover mr-1"
            >
              {{ $t('confirm') }}
            </button>
          </div>

          <div v-show="isUploading || isSubmit" class="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span class="loading--text">
              {{ isUploading ? $t('uploading') : '' }}
            </span>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>
  </v-dialog>
</template>

<script>
// vuex
import { mapState } from 'vuex'

// utils
import Decimal from '@/utils/decimal.min.js'

export default {
  name: 'RechargeDialog',

  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },

    paymentMethods: {
      type: Array,
      default: () => []
    },

    from: {
      type: String,
      default: '/user/wallet'
    }
  },

  data() {
    return {
      isSubmit: false,
      isUploading: false,
      walletAddress: null,
      type: null,
      file: null,
      params: {
        amount: 0,
        payment_option: null,
        trx_id: null,
        photo: null
      },
      formEle: null,

      bankInfo: '',

      carouselOption: {
        slidesPerView: 1,
        spaceBetween: 20,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
        // breakpoints: {
        //   0: {
        //       slidesPerView: 1,
        //       spaceBetween: 0
        //   },
        //   // when window width is >= 320px
        //   599: {
        //       slidesPerView: 2,
        //       spaceBetween: 0
        //   },
        //   // when window width is >= 480px
        //   960: {
        //       slidesPerView: 3,
        //       spaceBetween: 20
        //   },
        //   // when window width is >= 640px
        //   1264: {
        //       slidesPerView: 4,
        //       spaceBetween: 20
        //   },
        //   1904: {
        //       slidesPerView: 4,
        //       spaceBetween: 20
        //   },
        // }
      },

      showPaymentInstruction: false,

      currencyData: [],

      menuCloseOnClick: true,

      currencyName: '',

      exchangeRate: 1,

      symbol: '$',

      minRecharge: 0,

      minRechargeAmount: '',

      currentBankIndex: 0,

      paymentId: 0,

      maxRecharge: 0,

      maxRechargeAmount: ''
    }
  },

  computed: {
    ...mapState('app', ['apiUrl', 'apiPath']),

    isVisible: {
      get: function () {
        return this.show
      },

      set: function () {}
    },

    walletAddressWithFormat() {
      return this.walletAddress
        ? this.walletAddress.replace(/<[^<>]+>/g, '').replace(/&nbsp;/gi, '')
        : ''
    },

    currentBank() {
      if (this.currentBankInfo && this.currentBankInfo.length > 0) {
        return this.currentBankInfo[this.currentBankIndex]
      }

      return {}
    },

    currentBankInfo() {
      const list =
        this.type === 'bank_payment' && this.bankInfo
          ? JSON.parse(this.bankInfo)
          : []

      return list
    },

    // swiper 开关
    swiperSwitch() {
      if (!this.currentBankInfo.length) return false

      let result = false

      // 只要有一个银行卡信息有值，则显示
      this.currentBankInfo.forEach((item) => {
        for (const key in item) {
          if (item[key]) {
            result = true
          }
        }
      })

      return result
    },

    currencyAmount() {
      let amount = new Decimal(this.params.amount || 0).mul(
        new Decimal(this.exchangeRate)
      )

      if (String(amount).includes('.')) {
        const splitAmount = String(amount).split('.')
        const int = splitAmount[0]
        let decimal = splitAmount[1]

        const subStringDecimal = String(decimal).substring(0, 2)

        if (String(decimal).length > 2) {
          decimal = Number(subStringDecimal) + 1
        }

        decimal =
          Number(decimal) >= 10 || Number(decimal) === 0
            ? String(decimal).replace(/0/g, '')
            : decimal
        amount = decimal ? `${int}.${decimal}` : int
      }

      return amount
    },

    amountErrorSwitch() {
      const isMin =
        this.minRechargeAmountSwitch &&
        this.minRechargeAmount > this.params.amount

      const isMax =
        this.maxRechargeAmountSwitch &&
        this.maxRechargeAmount < this.params.amount

      return isMin || isMax
    }
  },

  watch: {
    paymentMethods() {
      const obj = this.paymentMethods[this.paymentMethods.length - 1]

      this.type = obj.type
      this.params.payment_option = obj.name

      this.minRecharge = obj.min_recharge_amount_switch
      this.maxRecharge = obj.max_recharge_amount_switch
      this.minRechargeAmount = obj.min_recharge_amount
      this.maxRechargeAmount = obj.max_recharge_amount

      this.walletAddress = obj.content
      this.bankInfo = obj.bank_info
      this.paymentId = obj.id
    }
  },

  created() {
    this.fetchCurrency()
  },

  methods: {
    changeBank(e) {
      const myBankSwiperIndex =
        this.$refs.myBankSwiper['swiperInstance']?.snapIndex || 0

      this.currentBankIndex = myBankSwiperIndex
    },
    /**
     * 充值
     */
    async handleSubmit() {
      if (this.isSubmit) return

      if (this.isUploading) {
        this.snack({
          message: this.$i18n.t('image_uploading'),
          color: 'red'
        })
        return
      }

      if (this.params.amount < 1) {
        this.snack({
          message: this.$i18n.t('please_input_recharge_amount'),
          color: 'red'
        })
        return
      }

      if (this.type === 'bank_payment' && !this.params.trx_id) {
        this.snack({
          message: this.$i18n.t('please_input_bank_name'),
          color: 'red'
        })
        return
      }

      if (this.type !== 'online_payment' && !this.params.photo) {
        this.snack({
          message: this.$i18n.t('please_upload_image'),
          color: 'red'
        })
        return
      }

      if (this.type === 'bank_payment') {
        this.params = {
          ...this.params,
          ...this.currentBank
        }
      }
      console.log('asasasas', this.currentBank, this.params)

      try {
        this.isSubmit = true
        const {
          data: { data, message, success }
        } = await this.call_api('post', 'user/wallet/recharge', {
          ...this.params,
          payment_method_id: this.paymentId
        })

        if (!success) {
          this.isSubmit = false
          return this.snack({
            message: message,
            color: 'red'
          })
        }
        // 线上充值
        if (this.type === 'online_payment' && data) {
          const jumpType = data.jump_type

          if (jumpType === 'form') {
            this.handleOnlinePayWithFrom(data)
          } else {
            window.location.href = data.url
          }
        }

        this.isSubmit = false
        this.snack({
          message: message,
          color: 'green'
        })
        this.$emit('onRefresh')
        this.onClose()
      } catch (e) {
        this.isSubmit = false
      }
    },

    // 切换货币单位
    switchCurrency({ name, exchange_rate, symbol }) {
      this.currencyName = name
      this.exchangeRate = exchange_rate
      this.symbol = symbol
    },

    /**
     * 线上充值回调逻辑处理
     */
    handleOnlinePayWithFrom(data) {
      const { url, params } = data
      const inputList = params.split('&')
      let inputEle = ''

      inputList.forEach((item) => {
        const name = item.split('=')[0]
        const value = item.split('=')[1]

        inputEle = inputEle + `<input value='${value}' name='${name}' />`
      })

      this.formEle = `<form
            action="${url}" name="onlinePay" method="post" enctype="multipart/form-data">${inputEle}
                <button type="submit" id="online_submit">submit</button>
            </form>
          `

      this.handleFormAction(this.formEle)
    },

    /**
     * 插入form表单并且提交到第三方充值平台
     */
    handleFormAction(html) {
      const divForm = document.getElementsByTagName('div_form')

      if (divForm.length) {
        document.body.removeChild(divForm[0])
      }

      const dom = document.createElement('div_form')
      dom.innerHTML = html
      document.body.appendChild(dom)
      document.forms[0].setAttribute('target', '_blank')
      document.onlinePay.submit()
    },

    /**
     * tabs切换
     */
    onTabs({
      name,
      type,
      content,
      bank_info,
      min_recharge_amount_switch,
      max_recharge_amount_switch,
      min_recharge_amount,
      max_recharge_amount,
      id
    }) {
      this.params.payment_option = name
      this.type = type
      this.walletAddress = content
      this.bankInfo = bank_info
      this.minRecharge = min_recharge_amount_switch
      this.maxRecharge = max_recharge_amount_switch
      this.minRechargeAmount = min_recharge_amount
      this.maxRechargeAmount = max_recharge_amount
      this.paymentId = id
    },

    // 获取货币列表
    async fetchCurrency() {
      try {
        const {
          data: { success, data }
        } = await this.call_api('get', 'currency/exchange/list')

        if (success) {
          this.currencyData = data

          const info = data.length ? data[0] : ''

          if (info) {
            this.currencyName = info.name
            this.symbol = info.symbol
            this.exchangeRate = info.exchange_rate
            this.paymentId = info.id
          }
        }
      } catch (error) {
        console.log('fetch')
      }
    },

    /**
     * 上传图片
     */
    async onUpLoad() {
      const { file } = this

      const data = new FormData()
      //使用append存储信息，append('键名','键值')

      data.append('aiz_file', file)
      data.append('type', file.type)
      data.append('name', file.name)
      data.append('relativePath', null)

      // this.call_api('post', 'aiz-uploader/upload', data)
      try {
        this.isUploading = true
        const res = await axios({
          method: 'post',
          url: `${this.apiUrl}/aiz-uploader/upload`,
          data,
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        this.params.photo = res.data.filepath
        this.isUploading = false
      } catch (e) {
        this.isUploading = false
      }
    },

    /**
     * 关闭弹窗
     */
    onClose() {
      this.isVisible = false
      this.$emit('onClose')
    }
  }
}
</script>

<style scoped>
@import './style/modal.css';
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
}

.loading--text {
  margin-top: 6px;
  font-size: 14px;
  color: #fff;
}

#manual_payment_description {
  word-break: break-all;
}

.recharge-text {
  height: 20px;
}
.min-recharge {
  font-size: 12px;
  margin-top: 10px;
  color: #333;
}

.title-bg {
  color: #FF3B3B;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
}

.btn-primary-vi {
  background-color: #FFA895;
  border-color: #FFA895;
  color: var(--white);
  opacity: 0.7;
  border-radius: 10px;
}
.btn-primary-vi:hover {
  opacity: 1;
}

.wallet-pay .modal-body > .align-items-center .aiz-megabox > input:checked ~ .aiz-megabox-elem, .aiz-megabox > input:checked ~ .aiz-megabox-elem {
  border-left: 5px solid #FFA895 !important;
}

.fc-vi {
  color: #FFA895;
}
</style>
